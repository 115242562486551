import React from 'react';
import 'styles/settings/fonts.css';
import 'styles/styles.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainHeader from 'components/ui/header';
import HomePage from 'pages/home';
import ServicesPage from 'pages/home/services';
import AboutPage from 'pages/home/about';
import PoliticsPage from 'pages/home/politics';
import PreloadPage from 'pages/preload';
import ContactModal from 'components/generals/modal/contact';
import BackToNarval from 'components/generals/backToNarval';
import MenuMovil from 'components/generals/menu-movil';
import MainFooter from 'components/ui/footer';
import { useLocation } from 'react-router-dom';

import 'animate.css';
import './i18n';
function App() {
    const { pathname } = useLocation();
    console.log(pathname);
    return (
        <>
            <MainHeader />
            <Routes>
                <Route path="/" element={<PreloadPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/policy" element={<PoliticsPage />} />
            </Routes>
            <MenuMovil />

            <BackToNarval />
            <ContactModal />
            {pathname !== '/' && pathname !== '/ptg' && (
                <>
                    <MainFooter />
                </>
            )}
        </>
    );
}

export default App;
